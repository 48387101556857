import * as React from 'react';
import { Container, Row, Col, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, DropdownToggle, NavLink, UncontrolledDropdown, Collapse, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';

export function GlobalFooter(props) {
    return (
        <footer>
            &copy; 2022
        </footer>
    )
}
