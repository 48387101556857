import * as React from 'react';
import { NavLink as RRNavLink } from "react-router-dom";
import { Container, Row, Col, Badge, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, DropdownToggle, NavLink, UncontrolledDropdown, Collapse, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';

import './index.css';

import iconHome from '../../icons/icon_home.svg';
import iconSearch from '../../icons/icon_search.svg';
import iconQueueReview from '../../icons/icon_queue_review.svg';
import iconQueueCommittee from '../../icons/icon_queue_committee.svg';
import iconQueueUpdate from '../../icons/icon_queue_update.svg';
import iconReformulary from '../../icons/icon_reformulary.svg';
import iconDatabase from '../../icons/icon_database.svg';
import { useQueueStats, useRefreshQueueStats } from '../../containers/QueueStatsProvider';

export function SideNav(props) {
    const queueStats = useQueueStats();
    const refreshQueueStats = useRefreshQueueStats();

    React.useEffect(() => {
      // setTimeout(() => {
        
      refreshQueueStats();

      // }, 2000);
    }, []);

    return (
       <Nav className='side-nav pt-5'>
        <NavItem>
          <NavLink to="/" tag={RRNavLink} activeClassName='active'>
            <img src={iconHome} alt='Icon - Home' className='icon pr-2'></img>
            ReFined Home
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/drugs/search" tag={RRNavLink} activeClassName='active'>
            <img src={iconSearch} alt='Icon - Search' className='icon pr-2'></img>
            Drug Search
          </NavLink>
        </NavItem>

        <hr />

        <NavItem>
          <NavLink to="/drugs/queue/noc/" tag={RRNavLink} activeClassName='active'>
            <img src={iconQueueReview} alt='Icon - Review Queue' className='icon pr-2'></img>
            Review Queue
            <Badge className='ml-2'>{ queueStats?.manual_review + queueStats?.noc_review +  queueStats?.dpd_review }</Badge>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/drugs/queue/com/" tag={RRNavLink} activeClassName='active'>
            <img src={iconQueueCommittee} alt='Icon - Committee Queue' className='icon pr-2'></img>
            Committee Queue
            <Badge className='ml-2'>{ queueStats?.committee }</Badge>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/drugs/queue/update/" tag={RRNavLink} activeClassName='active'>
            <img src={iconQueueUpdate} alt='Icon - Update Queue' className='icon pr-2'></img>
            Update Queue
            <Badge className='ml-2'>{ queueStats?.update }</Badge>
          </NavLink>
        </NavItem>

        <hr />

        <NavItem>
          <NavLink to="/drugs/" tag={RRNavLink} activeClassName='active'>
            <img src={iconReformulary} alt='Icon - Reformulary' className='icon pr-2'></img>
            Reformulary
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/drugs/" tag={RRNavLink} activeClassName='active'>
            <img src={iconDatabase} alt='Icon - Database' className='icon pr-2'></img>
            Database Files
          </NavLink>
        </NavItem>

       </Nav>
    )
}
