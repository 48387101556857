import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class MoveDrugModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
          <ModalHeader toggle={this.props.toggle}>Move Drug Record</ModalHeader>
          <ModalBody>
            <span dangerouslySetInnerHTML={{__html: this.props.modalText}}></span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}
            <Button color="primary" onClick={() => { this.props.toggle(); this.props.moveModalConfirm(); }}>Move Drug Record</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default MoveDrugModal;