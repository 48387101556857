import * as React from 'react';
import { Container, Row, Col, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, DropdownToggle, NavLink, UncontrolledDropdown, Collapse, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';
import './index.css';
import {
 
  useNavigate
} from "react-router-dom";
import reLogo from '../../icons/re_logo.svg';
import { AuthProvider, useGetUserInfo, useLogout } from '../../containers/AuthProvider';
export function TopNav(props) {
    let navigate = useNavigate();
    const { logout,
      isLoggingOut,
      logoutError,
      clearLogoutError } = useLogout();
    async function  logoutUser(){
      await logout(); 
    }
  
    const userInfo = useGetUserInfo();

    console.log(userInfo);

    const getInitials = (userInfo) => {
      return `${userInfo.firstName[0]}${userInfo.lastName[0]}`.toUpperCase();
    }

    return (
        <Navbar
        expand="md"
        className='top-nav pt-3 pb-3'
      >
        <NavbarBrand href="/">
          <img src={reLogo} className="reLogo" alt="logo" />
          <div className="logoText">ReFined</div>
        </NavbarBrand>
      
        <NavbarToggler onClick={function noRefCheck(){}} />
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown
                inNavbar
                nav
              >
                <DropdownToggle
                  caret
                  nav
                >
                  { userInfo.firstName && (
                    <>
                      <span className='user-initials mr-3'>{ getInitials(userInfo) }</span>
                      <span className="mr-2">{ userInfo.firstName } { userInfo.lastName }</span>
                    </>
                  )}

                  { !userInfo.firstName && (
                    <span>{ userInfo.userName }</span>
                  )}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem divider />
                  <DropdownItem onClick={logoutUser}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    )
}