import * as React from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import { useQueueStats } from '../../containers/QueueStatsProvider';

import './index.css';

export function HomePage() {
    useEffect(() => {
        document.body.classList.remove('login-page');
    });

    const queueStats = useQueueStats();

    return (
        <main className="background-reformulary home">
            <Container className='pt-5'>
                <Row>
                    <Col md={{ offset: 3, size: 6 }}>
                        <div className="item p-3 dark mb-4 justify-content-between">
                            <div>
                                <p className="title">
                                    Monthly Update
                                </p>
                                <p className="aside">
                                    Current Table: { queueStats?.manual_review }
                                </p>
                            </div>
                            <div className="text-right">
                                <NavLink to='/drugs/queue/manual/'>
                                    <Button>
                                        Review Queue
                                    </Button>
                                </NavLink>
                            </div>
                        </div>

                        <div className="item p-3 d-flex justify-content-between">
                            <div>
                                <p className="title">
                                    Drug Product Database (DPD)
                                </p>
                                <p className="aside">
                                    Health Canada
                                </p>
                            </div>
                            <div className="text-right aside">
                                Uploaded 2021-12-04
                            </div>
                        </div>

                        <div className="item p-3 d-flex justify-content-between">
                            <div>
                                <p className="title">
                                    Notice of Compliance (NOC)
                                </p>
                                <p className="aside">
                                    Health Canada
                                </p>
                            </div>
                            <div className="text-right aside">
                                Uploaded 2021-12-04
                            </div>
                        </div>

                        <div className="item p-3 d-flex justify-content-between">
                            <div>
                                <p className="title">
                                    Reformulary
                                </p>
                                <p className="aside">
                                    Health Canada
                                </p>
                            </div>
                            <div className="text-right aside">
                                Last Updated 2021-12-22
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </main>
    );
}
