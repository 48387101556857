import React from 'react';
import config from '../../config';

const PromiseContext = React.createContext(null);
const QueueStatsContext = React.createContext(null);
const RefreshQueueStatsContext = React.createContext(() => null);   

export function useQueueStats() {
    return React.useContext(QueueStatsContext);
}

export function useRefreshQueueStats() {
    return React.useContext(RefreshQueueStatsContext);
}

export function QueueStatsProvider (props) {
  const queueStatsPromiseRef = React.useRef<Promise>(null);
  const [queueStats, setQueueStats] = React.useState();
  
  const refreshQueueStats = React.useMemo(
    () => async () => {
      
      const searchUrl = new URL(`${config.REFINED_API_HOST}/api/drugs/count_review/`);
      const response = await fetch(searchUrl.href, {
          // headers: accessToken
          // ? {
          //     AUTHTOKEN: accessToken,
          //     }
          // : {},
      });

      const data = await response.json();
      setQueueStats(data);
    },
    [setQueueStats]
  );

  return (
    <PromiseContext.Provider value={queueStatsPromiseRef}>
      <QueueStatsContext.Provider value={queueStats}>
        <RefreshQueueStatsContext.Provider value={refreshQueueStats}>
          {props.children}
        </RefreshQueueStatsContext.Provider>
      </QueueStatsContext.Provider>
    </PromiseContext.Provider>
  );
}
