import * as React from 'react';
import { Container, Row, Col, Button,  Input } from 'reactstrap';
import { DrugCell } from '../../components/DrugCell';
import { moveDrugUpdateQueue, moveDrugCommitteeQueue, moveDrugManualReviewQueue } from '../../data';
import { useRefreshQueueStats } from '../../containers/QueueStatsProvider';
import { Toaster, toast } from 'react-hot-toast';

import config from '../../config';

import './index.css';

export function DrugSearchPage() {

    const [ searchType, setSearchType ] = React.useState('din'); 
    const [ searchTerm, setSearchTerm ] = React.useState(''); 
    const [ searchResults, setSearchResults ] = React.useState({}); 

    const refreshQueueStats = useRefreshQueueStats();

    const searchDrugs = async (event) => {
        event.preventDefault();

        const searchUrl = new URL(`${config.REFINED_API_HOST}/api/drugs/`);

        switch(searchType) {
            case 'din':
                searchUrl.searchParams.append('din', searchTerm);
                break;
            case 'drug_name':
                searchUrl.searchParams.append('drug_name', searchTerm);
                break;
            case 'active_ingredient':
                searchUrl.searchParams.append('active_ingredient__name', searchTerm);
                break;
        }

        const response = await fetch(searchUrl.href, {
            // headers: accessToken
            // ? {
            //     AUTHTOKEN: accessToken,
            //     }
            // : {},
        });

        const data = await response.json();
        setSearchResults(data);
    }

    const actionUpdateQueue = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugUpdateQueue(drug);

        if (updateStatus) {
            refreshQueueStats();

            drug.queue = 'update';

            const resultsCopy = {...searchResults};
            resultsCopy.results.forEach((element, index) => {
                if(element.id === drug.id) {
                    resultsCopy.results[index] = drug;
                }
            });

            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to update queue.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to update queue, an error occured.`);
        }
    }

    const actionComitteeQueue = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugCommitteeQueue(drug);
        if (updateStatus) {
            refreshQueueStats();

            drug.queue = 'com';

            const resultsCopy = {...searchResults};
            resultsCopy.results.forEach((element, index) => {
                if(element.id === drug.id) {
                    resultsCopy.results[index] = drug;
                }
            });

            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to expert committee queue.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to expert committee queue, an error occured.`);
        }
    }

    const actionManualReviewQueue = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugManualReviewQueue(drug);
        if (updateStatus) {
            refreshQueueStats();

            drug.queue = 'manual';

            const resultsCopy = {...searchResults};
            resultsCopy.results.forEach((element, index) => {
                if(element.id === drug.id) {
                    resultsCopy.results[index] = drug;
                }
            });

            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to manual review queue.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to manual review queue, an error occured.`);
        }
    }

    return (
        <main className="background-reformulary drug-search">
            
            <Container className='pt-5'>
                <Row>
                    <Col md={{ offset: 1, size: 10 }}>
                        <h1>Drug Search</h1>

                        <form onSubmit={searchDrugs} className='mt-5 mb-5'>
                            <Row>
                                <Col md='3'>
                                    <select className='form-control' onChange={ (event) => { setSearchType(event.target.value) } } value={searchType} >
                                        <option value='din'>DIN</option>
                                        <option value='active_ingredient'>Active Ingredient</option>
                                        <option value='drug_name'>Drug/Product Name</option>
                                    </select>
                                </Col>
                                <Col md='7'>
                                    <Input type='text' placeholder='Enter a DIN' value={searchTerm} onChange={ (event) => { setSearchTerm(event.target.value)} } />
                                </Col>
                                <Col md='2'>
                                    <Button className='btn-search' type='submit'>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </form>

                        <hr />

                        { searchResults?.results?.map(drug => (
                            <Row className='mb-2'>
                                <Col>
                                    <DrugCell
                                        drug={drug}
                                        actionUpdateQueue={actionUpdateQueue}
                                        actionComitteeQueue={actionComitteeQueue}
                                        actionManualReviewQueue={actionManualReviewQueue}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Container>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

        </main>
    );
}
