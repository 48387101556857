import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Outlet } from "react-router-dom";
import { TopNav } from '../../components/TopNav';
import { SideNav } from '../../components/SideNav';
import { GlobalFooter } from '../../components/GlobalFooter';
import { Navigate } from 'react-router-dom'
import { useIsLoggedIn } from '../../containers/AuthProvider';


export function Page(props) {
    const loggedIn = useIsLoggedIn();

    if (!loggedIn) {
        return (<Navigate replace to={`/login?next=${window.location.href}`} />);
    }

    return (
        <>
            <TopNav />
            <main>
                <Container fluid={true}>
                    <Row>
                        <Col md={3} className='pl-0'>
                            <SideNav />
                        </Col>
                        <Col md={9}>
                            <Outlet />
                        </Col>
                    </Row>
                </Container>
            </main>
            <GlobalFooter />
        </>
      );
}
