import * as React from 'react';
import { useEffect } from 'react';
import { Container, Row, Col, Button, Input, InputGroup, Label } from 'reactstrap';
import { useIsLoggedIn, useLogin } from '../../containers/AuthProvider';
import { Navigate } from 'react-router-dom'
import './index.css';

export function LoginPage() {
    const { login,
        isLoggingIn,
        loginError,
        clearLoginError } = useLogin();
    
    const isLoggedIn = useIsLoggedIn();

    useEffect(() => {
        document.body.classList.add('login-page');
    });


    const [ username, setUsername ] = React.useState('');
    const [ password, setPassword ] = React.useState('');

    const submitLogin = (event) => {
        event.preventDefault();

        login({
            username,
            password,
        });
    }

    if (isLoggedIn) {
        return (<Navigate replace to="/" />);
    }

    return (
        <main className="background-reformulary light login">
            <Container className='pt-5'>
                <Row>
                    <Col md={{ offset: 3, size: 6 }} className='pt-5'>
                        <form onSubmit={submitLogin} className='mt-5'>
                            <h1 className='text-center'>Sign In</h1>
                            <hr className='mt-4 mb-4' />
                            <InputGroup className='mb-3'>
                                <Label for='username'>Username</Label>
                                <Input type="text" required={true} value={username} onChange={ (event) => { setUsername(event.target.value) } } />
                            </InputGroup>
                            <InputGroup className='mb-4'>
                                <Label for='password'>Password</Label>
                                <Input type="password" required={true} value={password} onChange={ (event) => { setPassword(event.target.value) } } />
                            </InputGroup>
                            <Button type='submit'>
                                Sign In
                            </Button>
                        </form>
                    </Col>
                </Row>
            </Container>
        </main>
    );
}
