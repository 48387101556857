import * as React from 'react';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from "react-router-dom";
import { Container, Row, Col, Button, Dropdown, DropdownItem, Input, Badge, 
    Nav, NavItem,NavLink, TabContent, TabPane, FormGroup, Label, InputGroupText, InputGroup,
    UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import Select from 'react-select'
import {
    useParams
  } from "react-router-dom";

import { DrugCell } from '../../components/DrugCell';
import { moveDrugUpdateQueue, moveDrugCommitteeQueue, moveDrugManualReviewQueue, moveDrugArchive } from '../../data';
import { useQueueStats, useRefreshQueueStats } from '../../containers/QueueStatsProvider';
import { Toaster, toast } from 'react-hot-toast';

import config from '../../config';

import iconDatabase from '../../icons/icon_database.svg';
import iconSearch from '../../icons/icon_search.svg';
import iconQueueCommittee from '../../icons/icon_queue_committee.svg';
import iconQueueUpdate from '../../icons/icon_queue_update.svg';


import './index.css';
import MoveDrugModal from '../../components/MoveDrugModal';

export function DrugQueuePage() {
    const { queueSlug } = useParams();

    const [ searchResults, setSearchResults ] = React.useState({}); 

    const queueStats = useQueueStats();
    const refreshQueueStats =useRefreshQueueStats();

    const loadDrugs = async () => {
        const searchUrl = new URL(`${config.REFINED_API_HOST}/api/drugs/`);

        searchUrl.searchParams.append('queue', queueSlug);

        const response = await fetch(searchUrl.href, {
            // headers: accessToken
            // ? {
            //     AUTHTOKEN: accessToken,
            //     }
            // : {},
        });

        const data = await response.json();
        setSearchResults(data);
    }

    React.useEffect(async () => {
        await loadDrugs();
    }, [queueSlug]);

    const queueName = {
        'update': 'Update Queue',
        'manual': 'Manual Review Queue',
        'com': 'Expert Committee Queue',
    }[queueSlug];

    const [ moveModalShow, setMoveModalShow] = React.useState(false);
    const [ moveModalText, setMoveModalText] = React.useState('');
    const [ moveModalConfirm, setMoveModalConfirm ] = React.useState( () => () => {} );

    const moveModalToggle = () => {
        setMoveModalShow(!moveModalShow);
    }

    const prepareUpdateQueue = (drug) => {
        setMoveModalText(`Your selection includes a drug record that exists in ${drug.queue}. Are you sure you want to move it to the <strong>Update</strong> queue?`);
        setMoveModalConfirm( () => () => actionUpdateQueue(drug) );
        moveModalToggle();
    }

    const actionUpdateQueue = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugUpdateQueue(drug);

        if (updateStatus) {
            refreshQueueStats();

            drug.queue = 'update';

            const resultsCopy = {...searchResults};
            resultsCopy.results = resultsCopy.results.filter(d => d.id !== drug.id);
            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to update queue.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to update queue, an error occured.`);
        }
    }

    const prepareCommitteeQueue = (drug) => {
        setMoveModalText(`Your selection includes a drug record that exists in ${drug.queue}. Are you sure you want to move it to the <strong>Expert Comittee Reivew</strong> queue?`);
        setMoveModalConfirm( () => () => actionComitteeQueue(drug) );
        moveModalToggle();
    }

    const actionComitteeQueue = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugCommitteeQueue(drug);
        if (updateStatus) {
            refreshQueueStats();

            drug.queue = 'com';

            const resultsCopy = {...searchResults};
            resultsCopy.results = resultsCopy.results.filter(d => d.id !== drug.id);
            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to expert committee queue.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to expert committee queue, an error occured.`);
        }
    }

    const prepareReviewQueue = (drug) => {
        setMoveModalText(`Your selection includes a drug record that exists in ${drug.queue}. Are you sure you want to move it to the <strong>Manual Review</strong> queue?`);
        setMoveModalConfirm( () => () => actionManualReviewQueue(drug) );
        moveModalToggle();
    }

    const actionManualReviewQueue = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugManualReviewQueue(drug);
        if (updateStatus) {
            refreshQueueStats();            

            drug.queue = 'manual';

            const resultsCopy = {...searchResults};
            resultsCopy.results = resultsCopy.results.filter(d => d.id !== drug.id);
            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to manual review queue.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to manual review queue, an error occured.`);
        }
    }

    const prepareArchive = (drug) => {
        setMoveModalText(`Your selection includes a drug record that exists in ${drug.queue}. Are you sure you want to move it to the <strong>Archive</strong>?`);
        setMoveModalConfirm( () => () => actionArchive(drug) );
        moveModalToggle();
    }

    const actionArchive = async (drug) => {
        // update drug queue
        const updateStatus = await moveDrugArchive(drug);
        if (updateStatus) {
            refreshQueueStats();

            // change drug info to mark as archived
            drug.in_reformulary = false;
            drug.queue = '';

            const resultsCopy = {...searchResults};
            resultsCopy.results = resultsCopy.results.filter(d => d.id !== drug.id);
            setSearchResults(resultsCopy);

            toast.success(`Drug ${drug.din} moved to archive.`);
        } else {
            toast.error(`Could not move drug ${drug.din} to archive.`);
        }
    }

    return (
        <main className="background-reformulary drug-queue-list">
            { ['manual', 'noc', 'dpd'].includes(queueSlug)  && (
                <Container fluid={true}>
                    <Row>
                        <Nav className='queue-sub-nav pt-4 pb-4'>
                            <NavItem>
                                <NavLink to="/drugs/queue/noc" tag={RRNavLink} activeClassName='active'>
                                    <img src={iconDatabase} alt='Icon - Database' className='icon pr-2'></img>
                                    NOC Review
                                    <Badge className='ml-2'>{ queueStats?.noc_review }</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/drugs/queue/dpd" tag={RRNavLink} activeClassName='active'>
                                    <img src={iconDatabase} alt='Icon - Database' className='icon pr-2'></img>
                                    DPD Review
                                    <Badge className='ml-2'>{ queueStats?.dpd_review }</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/drugs/queue/manual" tag={RRNavLink} activeClassName='active'>
                                    <img src={iconSearch} alt='Icon - Magnifying Glass' className='icon pr-2' />
                                    Manual Review
                                    <Badge className='ml-2'>{ queueStats?.manual_review }</Badge>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                </Container>
            )}

            { ['com'].includes(queueSlug)  && (
                <Container fluid={true}>
                    <Row className='queue-sub-nav pt-4 pb-4'>
                        <Col>
                            <h1 className='m-0'>Expert Committee Review Queue</h1>
                        </Col>
                    </Row>
                </Container>
            )}

            { ['update'].includes(queueSlug)  && (
                <Container fluid={true}>
                    <Row className='queue-sub-nav pt-4 pb-4'>
                        <Col>
                            <h1 className='m-0'>Update Queue</h1>
                        </Col>
                        <Col className='text-right'>
                            <Button size='md' className='btn-publish'>
                                Publish Update
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}

            <Container className='pt-5'>
                <Row>
                    <Col md={{ size: 10 }}>
                        
                    { searchResults?.results?.map(drug => (
                            <Row className='mb-2'>
                                <Col>
                                    <DrugCell
                                        drug={drug}
                                        actionUpdateQueue={ queueSlug !== 'update' ? prepareUpdateQueue : undefined }
                                        actionComitteeQueue={queueSlug !== 'com' ? prepareCommitteeQueue : undefined}
                                        actionManualReviewQueue={ !['dpd', 'noc', 'manual'].includes(queueSlug) ? prepareReviewQueue : undefined}
                                        actionArchive={prepareArchive}
                                    />
                                </Col>
                            </Row>
                        ))}         

                    </Col>
                </Row>
            </Container>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <MoveDrugModal
                modal={moveModalShow}
                toggle={moveModalToggle}
                modalText={moveModalText}
                moveModalConfirm={moveModalConfirm}
            />
        </main>
    );
}
