import * as React from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Badge, Button, Tooltip } from 'reactstrap';

import iconQueueReview from '../../icons/icon_queue_review.svg';
import iconQueueCommittee from '../../icons/icon_queue_committee.svg';
import iconQueueUpdate from '../../icons/icon_queue_update.svg';
import iconArchive from '../../icons/icon_archive.svg';

import './index.css';

export function DrugCell(props) {
    const { 
        drug, 
        actionUpdateQueue, 
        actionComitteeQueue, 
        actionManualReviewQueue, 
        actionArchive,
    } = props;

    const [ tooltipUpdateQueue, setTooltipUpdateQueue ] = React.useState(false);
    const [ tooltipCommitteeQueue, setTooltipCommitteeQueue ] = React.useState(false);
    const [ tooltipReviewQueue, setTooltipReviewQueue ] = React.useState(false);
    const [ tooltipArchive, setTooltipArchive ] = React.useState(false);

    return (
        <div className="drug-info">
            <Row>
                <Col md='5'>
                    <p className="din mb-1">
                        <Link to={`/drugs/${drug.id}`}>
                            { drug.din }
                        </Link>
                    </p>
                    <p className="name">
                        { drug.drug_name }
                    </p>
                    <p className="active-ingredient mb-0">
                        { drug.active_ingredient.map(ai => ai.name).join(',') }
                    </p>
                    <p className="schedule">
                        { drug.schedule.map(schedule => schedule.name).join(',') } - { drug.administration.map(admin => admin.name).join(',') }
                    </p>

                </Col>
                <Col md='4' className='text-right'>
                { drug.queue === 'update' && (
                        <Badge>In Update Queue</Badge>
                    )}
                { drug.queue === 'com' && (
                        <Badge>In Committee Queue</Badge>
                    )}
                { drug.queue === 'manual' && (
                        <Badge>In Manual Update Queue</Badge>
                    )}
                { drug.queue === '' && (
                        <Badge>In Archive</Badge>
                    )}
                { drug.in_reformulary && (
                        <Badge>On Reformulary</Badge>
                    )}
                </Col>
                <Col md='3' className='text-right'>
                    <div className='actionsList'>
                        { actionUpdateQueue && (
                            <>
                                <button onClick={() => { actionUpdateQueue(drug) }} id="btn_mv_update">
                                    <img src={iconQueueUpdate} alt='Icon - Update Queue' className='icon pr-2' />
                                </button>
                                <Tooltip placement="bottom" isOpen={tooltipUpdateQueue} target="btn_mv_update" toggle={() => { setTooltipUpdateQueue( ! tooltipUpdateQueue ) }}>
                                    Move to <strong>Update</strong> queue
                                </Tooltip>
                            </>
                        )}

                        { actionComitteeQueue && (
                            <>
                                <button onClick={() => { actionComitteeQueue(drug) }} id="btn_mv_committee">
                                    <img src={iconQueueCommittee} alt='Icon - Comittee Queue' className='icon pr-2' />
                                </button>
                                <Tooltip placement="bottom" isOpen={tooltipCommitteeQueue} target="btn_mv_committee" toggle={() => { setTooltipCommitteeQueue( ! tooltipCommitteeQueue ) }}>
                                    Move to <strong>Expert Committee Review</strong> queue
                                </Tooltip>
                            </>
                        )}

                        { actionManualReviewQueue && (
                            <>
                                <button onClick={() => { actionManualReviewQueue(drug) }} id="btn_mv_review">
                                    <img src={iconQueueReview} alt='Icon - Review Queue' className='icon pr-2' />
                                </button>
                                <Tooltip placement="bottom" isOpen={tooltipReviewQueue} target="btn_mv_review" toggle={() => { setTooltipReviewQueue( ! tooltipReviewQueue ) }}>
                                    Move to <strong>Review</strong> queue
                                </Tooltip>
                            </>
                        )}

                        { actionArchive && (
                            <>
                                <button onClick={() => { actionArchive(drug) }} id="btn_mv_archive">
                                    <img src={iconArchive} alt='Icon - Archive' className='icon pr-2' />
                                </button>
                                <Tooltip placement="bottom" isOpen={tooltipArchive} target="btn_mv_archive" toggle={() => { setTooltipArchive( ! tooltipArchive ) }}>
                                    Move to <strong>Archived</strong>
                                </Tooltip>
                            </>
                        )}
                    </div>
                    <p>
                        <strong>Drug/Product Type:</strong> { drug.type.name }
                    </p>
                    <p>
                        <strong>RG Tier: { drug.tier }</strong>
                    </p>
                </Col>
            </Row>
        </div>
    )
}
