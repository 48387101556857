import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools';
// import { ThemeProvider } from 'styled-components/macro';
// import { theme } from './theme';
import { BrowserRouter, Routes, Route, Switch } from 'react-router-dom';
// import { ReactQueryDevtools } from 'react-query-devtools';

import { AuthProvider } from './containers/AuthProvider';
import { QueueStatsProvider } from './containers/QueueStatsProvider';

import { Page } from './pages/Page';
import PrivateRoute from './containers/PrivateRoute';

import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { DrugsPage } from './pages/DrugsPage';
import { DrugSearchPage } from './pages/DrugSearchPage';
import { DrugEditPage } from './pages/DrugEditPage';
import { DrugQueuePage } from './pages/DrugQueuePage';

// import logo from './logo.svg';
import './App.css';

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-N33LBQ2'
}

TagManager.initialize(tagManagerArgs)
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

function App() {

  return (
      <Providers>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Page />}>
            <Route index element={<HomePage />} />
            <Route path="drugs">
              <Route path="search" element={<DrugSearchPage />} />
              <Route path=":drugId" element={<DrugEditPage />} /> 
              <Route path="queue/:queueSlug" element={<DrugQueuePage />} /> 
            </Route>
          </Route>
        </Routes>
      </Providers>
  );
}

{/* <Routes>
  <Route path="/" element={<App />}>
    <Route index element={<Home />} />
    <Route path="teams" element={<Teams />}>
      <Route path=":teamId" element={<Team />} />
      <Route path=":teamId/edit" element={<EditTeam />} />
      <Route path="new" element={<NewTeamForm />} />
      <Route index element={<LeagueStandings />} />
    </Route>
  </Route>
  <Route element={<PageLayout />}>
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/tos" element={<Tos />} />
  </Route>
  <Route path="contact-us" element={<Contact />} />
</Routes> */}

function Providers (props) {
  const queryClient = new QueryClient()
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <QueueStatsProvider>
            <BrowserRouter>{props.children}</BrowserRouter>
            <ReactQueryDevtools />
          </QueueStatsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
