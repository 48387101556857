import * as React from 'react';
import { Container, Row, Col, Button,Input, 
    Nav, NavItem,NavLink, TabContent, TabPane, FormGroup, Label, InputGroupText, InputGroup,
    UncontrolledCollapse,  } from 'reactstrap';
import Select from 'react-select'
import {
    useParams
  } from "react-router-dom";
import config from '../../config';
import { Toaster, toast } from 'react-hot-toast';
import './index.css';

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

const getDrugInfo = async (drugId) => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drugs/${drugId}`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getDrugTypes = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drug_type/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getDrugSchedule = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drug_schedule/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getActiveIngredient = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/active_ingredient/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getRouteOfAdministration = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/administration/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getManufacturer = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/manufacturer/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getSubMedical= async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/sub_medical_condition/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

const getDrugClass = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drug_class/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}
const getGFPeriod = async () => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/gf_period/`);
    const response = await fetch(fetchUri, {
        // headers: accessToken
        // ? {
        //     AUTHTOKEN: accessToken,
        //     }
        // : {},
    });
    return await response.json();
}

export function DrugEditPage() {
    const { drugId } = useParams();

    const [ drugDIN, setDrugDIN ] = React.useState('');
    const [ drigPIN, setDrugPIN ] = React.useState('');
    const [ drugName, setDrugName ] = React.useState('');
    const [ drugHealthCanadaName, setHealthCanadaName ] = React.useState('');
    const [ manufacturer, setManufacturer ] = React.useState('');
    const [ activeIngredient, setActiveIngredient ] = React.useState([]);
    const [ routeOfAdministration, setRouteOfAdministration ] = React.useState([]);
    const [ submedical, setSubMedical ] = React.useState([]);
    const [ drugType, setDrugType ] = React.useState('');
    const [ drugTypeSub, setDrugTypeSub ] = React.useState('');
    const [ drugSchedule, setDrugSchedule ] = React.useState([]);
    const [ drugClass, setDrugClass ] = React.useState([]);
    const [ gfperiod, setGFPeriod] = React.useState('')
    const [ drugForm, setDrugForm ] = React.useState('');
    const [ drugStrength, setDrugStrength ] = React.useState('');

    const [ optionsDrugTypeSubs, setOptionsDrugSubTypes ] = React.useState([]);
    const [ optionsDrugSchedule, setOptionsDrugSchedule ] = React.useState([]);
    const [ optionsActiveIngredient, setOptionsActiveIngredient ] = React.useState([]);
    const [ optionsRouteAdministration, setOptionsRouteAdministration ] = React.useState([]);
    const [ optionsManufacturer, setOptionsManufacturer ] = React.useState([]);
    const [ optionsSubMedical, setOptionsSubMedical ] = React.useState([]);
    const [ optionsDrugClass, setOptionsDrugClass ] = React.useState([]);
    const [ optionsGFPeriod, setOptionsGFPeriod ] = React.useState([]);



    React.useEffect(() => {
        const drugSchedules = getDrugSchedule();
        drugSchedules.then(async function(responseData) {
            setOptionsDrugSchedule(responseData.results.map(dt => ({
                value: dt.id,
                label: dt.name,
            })));
        });  
        const drugTypes = getDrugTypes();
        drugTypes.then(async function(responseData) {
            setOptionsDrugSubTypes(responseData.results.map(dt => ({
                value: dt.id,
                label: dt.name,
            })));
        });
        const activeingredient = getActiveIngredient();
        activeingredient.then(async function(responseData) {
            setOptionsActiveIngredient(responseData.results.map(dt => ({
                value: dt.id,
                label: dt.name,
            })));
        });  
        const administration = getRouteOfAdministration();
        administration.then(async function(responseData) {
            setOptionsRouteAdministration(responseData.results.map(dt => ({
                value: dt.id,
                label: dt.name,
            })));
        });  
        const manufacturer = getManufacturer();
        manufacturer.then(async function(responseData) {

            setOptionsManufacturer(responseData.results.map(dt => ({
                value: dt.id,
                label: dt.name,
            })));
        });  

        const submedical = getSubMedical();
        submedical.then(async function(responseData) {

            setOptionsSubMedical(responseData.results.map(dt => ({
                value: dt.id,
                label: dt.name,
            })));
        });  
        const drugclass = getDrugClass();
        drugclass.then(async function(responseData) {
            setOptionsDrugClass(responseData.results.map(dt => 
                ({
                value: dt.id,
                label: dt.name,
            })));
        });  
        const gf = getGFPeriod();
        gf.then(async function(responseData) {

            setOptionsGFPeriod(responseData.results.map(dt => ({
                value: dt.lookup_id,
                label: dt.name,
            })));
        });  
    }, []);
    React.useEffect(() => {
        
        console.log(drugTypeSub);
        if(drugTypeSub){
            if(drugTypeSub.label.endsWith("Brand")) {
                setDrugName(drugName.toUpperCase());
            }else {
                setDrugName(drugName.toProperCase());
            }
        }
    },[drugTypeSub]);
    React.useEffect( () => {
        // this code will execute every time one of the 'options' variables becomes populated...
        // once they are all populated (check with if statement), then we are ready to pull our info for the drug
        // info and do the mapping of the relationships
        if (optionsDrugTypeSubs.length !== 0 && 
            optionsManufacturer.length !== 0 && 
            optionsDrugSchedule.length !== 0 && 
            optionsActiveIngredient.length !== 0 && 
            optionsRouteAdministration.length !== 0 && 
            optionsManufacturer.length !== 0 && 
            optionsSubMedical.length !== 0 &&
            optionsDrugClass.length !== 0 &&
            optionsGFPeriod.length !== 0) {
            const drug = getDrugInfo(drugId);
            drug.then(async function(responseData) {
                setDrugDIN(responseData.din);
                setDrugPIN(responseData.pin);
                setDrugName(responseData.drug_name);
                setHealthCanadaName(responseData.health_canada_drug_name);
                var drugType = optionsDrugTypeSubs.find(dt =>  responseData.type.id === dt.value )
                setDrugTypeSub(drugType );
                setDrugClass(optionsDrugClass.find(dt=> dt.value === responseData.drug_class.id))
                setManufacturer( optionsManufacturer.find(dt => dt.value === responseData.manufacturer));
                if(responseData.grandfather_period){
                    setGFPeriod(optionsGFPeriod.find(dt => dt.value === responseData.grandfather_period.lookup_id));
                }else{
                    setGFPeriod(optionsGFPeriod.find(dt => dt.value === '100'));
                }
                setDrugSchedule(optionsDrugSchedule.filter(ds => responseData.schedule.map(s => s.id).indexOf(ds.value) >=0));
                setActiveIngredient(optionsActiveIngredient.filter(ds => responseData.active_ingredient.map(s => s.id).indexOf(ds.value) >=0));
                setRouteOfAdministration(optionsRouteAdministration.filter(ds => responseData.administration.map(s => s.id).indexOf(ds.value) >=0));
                setSubMedical(optionsSubMedical.filter(ds => responseData.sub_medical_condition.map(s => s.id).indexOf(ds.value) >=0));
                setDrugForm(responseData.form);
                setDrugStrength(responseData.strength);
            });    
        }
    }, [drugId, optionsDrugTypeSubs, optionsDrugSchedule, optionsActiveIngredient, optionsRouteAdministration,optionsManufacturer,optionsSubMedical, optionsDrugClass,optionsGFPeriod]);

    const saveForm = async () => {
        const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drugs/${drugId}/`);
        console.log(gfperiod);
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                //id: drugId,
                din: drugDIN,
                pin: drigPIN,
                drug_name: drugName, 
                health_canada_drug_name: drugHealthCanadaName,
                manufacturer: manufacturer.id,
                active_ingredient: activeIngredient,
                administration: routeOfAdministration,
                type: drugTypeSub,
                schedule: drugSchedule,
                sub_medical_condition: submedical,
                drug_class: drugClass,
                form: drugForm,
                strength: drugStrength,
                grandfather_period: {
                    lookup_id: gfperiod.value,
                    name: gfperiod.label
                },
            })
        };

        const response = await fetch(fetchUri, requestOptions);
        if (response.ok) {
            toast.success('Drug data successfully updated!');
        } else {
            toast.error('There was a problem updating the drug data.');
        }
    }

    return (
        <main className="background-reformulary drug-edit">

        {/* { optionsDrugTypes.map(dt => (
            <h1>Hi { dt.name }</h1>
        ))} */}

            <Container className='pt-5'>
                <Row>
                    <Col md={{ offset: 1, size: 10 }}>
                        
                        <Row>
                            <Col md={{offset:1, size:2}}>
                                <div>
                                    <h2 className="title">
                                        { drugDIN }
                                    </h2>
                                    <p className="aside">
                                        RefID -
                                    </p>
                                </div>
                            </Col>
                            <Col md={{offset:8, size:1}}>
                                <Button type="button" onClick={saveForm}>
                                    Done
                                </Button>
                            </Col>
                            <hr />
    
                        </Row>
                        <Row>
                            <Col md={{size:12}}>
                            <Nav tabs>
                                <NavItem>
                                <NavLink
                                    className="active"
                                    onClick={function noRefCheck(){}}
                                >
                                    Drug Details
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className=""
                                    disabled={true}
                                    onClick={function noRefCheck(){}}
                                >
                                    Insurer Details
                                </NavLink>
                                </NavItem>
                                
                                <NavItem>
                                <NavLink
                                    className=""
                                    disabled={true}
                                    onClick={function noRefCheck(){}}
                                >
                                    Comments
                                </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab="1">
                                <TabPane tabId="1">
                                <Row>
                                    <Col md={{size:12}}>
                                    <form>
                                        <Row className='formgroup'>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="drugname" className='label'>
                                                Drug/Product Name
                                                </Label>
                                                <Input
                                                id="drugname"
                                                name="drugname"
                                                className='textbox'
                                                placeholder="placeholder text"
                                                type="text"
                                                value={drugName}
                                                onChange={(e) => { setDrugName(e.target.value) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                        <FormGroup>
                                                <Label for="drugsubtype" className='label'>
                                                Drug Sub-Type
                                                </Label>
                                                <Select
                                                id="drugsubtype"
                                                name="drugsubtype"
                                                className='textbox'
                                                value={ drugTypeSub }
                                                onChange={(e) => { setDrugTypeSub(e) }}
                                                options={ optionsDrugTypeSubs }
                                                />
                                            </FormGroup>
                                           
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="manufacturer" className='label'>
                                                Manufacturer
                                                </Label>
                                                <Select
                                                id="manufacturer"
                                                name="manufacturer"
                                                value={manufacturer}
                                                onChange={(e) => { setManufacturer(e) }}
                                                options={optionsManufacturer}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="schedule" className='label'>
                                                Schedule
                                                </Label>
                                                <Select
                                                isMulti
                                                id="schedule"
                                                name="schedule"
                                                className='textbox'
                                                placeholder="placeholder text"
                                                value={drugSchedule}
                                                onChange={(e) => { setDrugSchedule(e) }}
                                                options={ optionsDrugSchedule }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="activeingredient" className='label'>
                                                Active Ingredient
                                                </Label>
                                                <Select
                                                isMulti
                                                isSearchable
                                                id="activeingredient"
                                                name="activeingredient"
                                                value={activeIngredient}
                                                onChange={(e) => { setActiveIngredient(e) }}
                                                options={optionsActiveIngredient}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="form" className='label'>
                                                Form
                                                </Label>
                                                <Select
                                                id="form"
                                                name="form"
                                                className='textbox'
                                                placeholder="placeholder text"
                                                value={drugForm}
                                                onChange={(e) => { setDrugForm(e.target.value) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="routeofadministration" className='label'>
                                                Route of Administration
                                                </Label>
                                                <Select
                                                id="routeofadministration"
                                                name="routeofadministration"
                                                value={routeOfAdministration}
                                                onChange={(e) => { setRouteOfAdministration(e) }}
                                                options={optionsRouteAdministration}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="strength" className='label'>
                                                Strength
                                                </Label>
                                                <Input
                                                id="strength"
                                                name="strength"
                                                className='textbox'
                                                type="text"
                                                value={drugStrength}
                                                onChange={(e) => { setDrugStrength(e.target.value) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{size:12}}>
                                            <div>
                                                <Button
                                                    className='mt-3'
                                                    color="primary"
                                                    id="toggler"
                                                    onClick={function noRefCheck(){}}
                                                    style={{
                                                    marginBottom: '1rem'
                                                    }}
                                                >
                                                    Show More/Less
                                                </Button>
                                                <UncontrolledCollapse toggler="#toggler">
                                                <Row className='formgroup'>
                                        <Col md={{size:12}}>
                                            <FormGroup>
                                                <Label for="submedical" className='label'>
                                                Sub-Medical Condition
                                                </Label>
                                                <Select
                                                isMulti
                                                isSearchable
                                                id="submedical"
                                                name="submedical"
                                                className='textbox'
                                                value={submedical}
                                                onChange={(e) => { setSubMedical(e) }}
                                                options={optionsSubMedical}
                                                />
                                            </FormGroup>
                                        </Col>
                                    
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="healthcanadadrugname" className='label'>
                                                Health Canada Drug Name
                                                </Label>
                                                <Input
                                                id="healthcanadadrugname"
                                                name="healthcanadadrugname"
                                                className='textbox'
                                                type="text"
                                                value={drugHealthCanadaName}
                                                onChange={(e) => { setHealthCanadaName(e.target.value) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                        <FormGroup>
                                                
                                                <Label for="drugtype" className='label'>
                                                Drug/Product Type
                                                </Label>
                                                <Select
                                                id="drugtype"
                                                name="drugtype"
                                                isDisabled
                                                // className='textbox'
                                                // placeholder="placeholder text"
                                                value={drugType}
                                                onChange={(e) => { 
                                                    setDrugType(e);
                                                 }}
                                                options={  [
                                                    { value: 'brand', label: 'Brand Name Drug' },
                                                    { value: 'generic', label: 'Generic Drug' },
                                                    { value: 'biosimilar', label: 'Biosimilar Drug' }
                                                  ] }
                                                >

                                                </Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="drugclass" className='label'>
                                                Drug Class
                                                </Label>
                                                <Select
                                                id="drugclass"
                                                name="drugclass"
                                                className='textbox'
                                                placeholder="placeholder text"
                                                value={ drugClass }
                                                onChange={(e) => { setDrugClass(e) }}
                                                options={ optionsDrugClass }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="gfperiod" className='label'>
                                                Grandfathering Period
                                                </Label>
                                                <Select
                                                id="gfperiod"
                                                name="gfperiod"
                                                className='textbox'
                                               
                                                value={ gfperiod }
                                                onChange={(e) => { setGFPeriod(e) }}
                                                options={ optionsGFPeriod }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="steptherapy" className='label'>
                                                Step Therapy
                                                </Label>
                                                <Select
                                                id="steptherapy"
                                                name="steptherapy"
                                                className='textbox'
                                                placeholder="placeholder text" f
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="quanitylimit" className='label'>
                                                Quantity Limits
                                                </Label>
                                                <InputGroup>
                                                    <Input
                                                    id="quanitylimit"
                                                    name="quanitylimit"
                                                    className='textbox'
                                                    placeholder="placeholder text"
                                                    type="text"
                                                    />
                                                    <InputGroupText>
                                                        Days at Tier 1
                                                    </InputGroupText>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="discontinueddate" className='label'>
                                                Discontinued Date
                                                </Label>
                                                <Input
                                                id="discontinueddate"
                                                name="discontinueddate"
                                                className='textbox'
                                                disabled={true}
                                                placeholder="placeholder text"
                                                type="date"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{size:6}}>
                                            <FormGroup>
                                                <Label for="action" className='label'>
                                                Action
                                                </Label>
                                                <Input
                                                id="action"
                                                name="action"
                                                className='textbox'
                                                placeholder="placeholder text"
                                                type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                                </UncontrolledCollapse>
                                            </div>
                                            </Col>
                                        </Row>
                                    </form>
                                    </Col>
                                </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                <Col sm="12">
                                    <h4>
                                        Tab 2 Contents
                                    </h4>
                                    </Col>
                                
                                </TabPane>
                            </TabContent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </main>
    );
}
