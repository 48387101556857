import React, { useEffect, useState } from 'react';
import {
  queryCache,
  useMutation,
} from 'react-query';
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import config from '../../config';

const PromiseContext = React.createContext(null);

export const AuthContext = React.createContext(null);
const SetAuthInfoContext = React.createContext(() => null);   

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export function useIsLoggedIn () {
  return Boolean(localStorage.getItem('authInfo'));
  // return Boolean(React.useContext(AuthContext));
}

export function useLogout () {
  const setAuthInfo = useSetAuthInfo();
  const authInfo = useAuthInfo();
  let navigate = useNavigate();
  const mutation = useMutation(
    async () => {
      const logout = await fetch(`${config.REFINED_API_HOST}/api/logout/`, {
        method: 'GET',
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authInfo.token}`
        },
        
      });
  
      if (!logout.ok) {
        throw new Error('An error occurred');
      }

     

      // if (!isAuthInfo(data)) {
      //   if (json.includes('badpassword') || json.includes('nosuchuser')) {
      //     const err = new Error('Invalid password error');
      //     (err).code = 'invalid-password';
      //     throw err;
      //   }

      //   throw new Error('Unexpected response');
      // }

      
      return true;
    },
    {
      onSuccess: () => {
        queryCache?.invalidateQueries('user');
        localStorage.clear();
        navigate("/login");
      }
    }
  );

  return {
    logout: mutation.mutate,
    isLoggingOut: mutation.isLoading,
    logoutError: mutation.error,
    clearLogoutError: mutation.reset,
  };
}

export function useLogin () {
  const setAuthInfo = useSetAuthInfo();
  const mutation = useMutation(
    async (params) => {
        let formData = new FormData();
        formData.append('username', params.username);
        formData.append('password', params.password);

      const res = await fetch(`${config.REFINED_API_HOST}/api/api-token-auth/`, {
        method: 'POST',
        body: formData
      });

      if (!res.ok) {
        throw new Error('An error occurred');
      }

      const json = await res.text();
      const data = JSON.parse(json);
      const user_data = await fetch(`${config.REFINED_API_HOST}/api/user/`, {
        method: 'GET',
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${data.token}`
        },
      });

      if (!user_data.ok) {
        throw new Error('An error occurred');
      }

      const user_text = await user_data.text();
      const user_json = JSON.parse(user_text);

      // if (!isAuthInfo(data)) {
      //   if (json.includes('badpassword') || json.includes('nosuchuser')) {
      //     const err = new Error('Invalid password error');
      //     (err).code = 'invalid-password';
      //     throw err;
      //   }

      //   throw new Error('Unexpected response');
      // }

      setAuthInfo(data, user_json);
      return true;
    },
    {
      onSuccess: () => {
        queryCache?.invalidateQueries('user');
      }
    }
  );

  return {
    login: mutation.mutate,
    isLoggingIn: mutation.isLoading,
    loginError: mutation.error,
    clearLoginError: mutation.reset,
  };
}



export function isAuthInfo (res) {
  return Boolean(
    typeof res === 'object' &&
      res &&
      typeof res.AUTH_TOKEN === 'string'
  );
}

export function useSetAuthInfo () {
  return React.useContext(SetAuthInfoContext);
}

export function AuthProvider (props) {
  const authPromiseRef = React.useRef<Promise>(null);
  const [authInfo, setAuthInfoState] = React.useState();
  
  const setAuthInfo = React.useMemo(
    () => (newAuthInfo, userData) => {
      if (!newAuthInfo) {
        localStorage.removeItem('authInfo');
        localStorage.removeItem('userData');
      } else {
        localStorage.setItem('authInfo', JSON.stringify(newAuthInfo));
        localStorage.setItem('userData', JSON.stringify(userData));
      }
      setAuthInfoState(newAuthInfo);
    },
    [setAuthInfoState]
  );

  return (
    <PromiseContext.Provider value={authPromiseRef}>
      <AuthContext.Provider value={authInfo}>
        <SetAuthInfoContext.Provider value={setAuthInfo}>
          {props.children}
        </SetAuthInfoContext.Provider>
      </AuthContext.Provider>
    </PromiseContext.Provider>
  );
}

export function useGetAccessToken () {
  const authInfo = React.useContext(AuthContext);

  // Returns an async function incase in the future the auth system changes and the
  // token needs to be refreshed every so often before being used
  return React.useCallback(async () => {
    if (!authInfo) {
      return null;
    }

    return authInfo.AUTH_TOKEN;
  }, [authInfo]);
}

export function useGetUserInfo () {
  const authInfo = JSON.parse(localStorage.getItem('userData'));

  const names = {
    userName: authInfo?.username,
    email: authInfo?.email,
    firstName :authInfo?.first_name,
    lastName : authInfo?.last_name
  };

  return names;
}

export function useAuthInfo () {
  const authInfo = JSON.parse(localStorage.getItem('authInfo'));

  const names = {
    token: authInfo?.token,
  };

  return names;
}
