import config from '../config';

export const moveDrugArchive = async (drug) => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drugs/${drug.id}/archive/`);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetch(fetchUri, requestOptions);
    return response.ok;  
}

export const moveDrugUpdateQueue = async (drug, queue) => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drugs/${drug.id}/update_review/`);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            queue: queue,
        })
    };

    const response = await fetch(fetchUri, requestOptions);
    return response.ok;
}

export const moveDrugCommitteeQueue = async (drug, queue) => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drugs/${drug.id}/committee_review/`);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            queue: queue,
        })
    };

    const response = await fetch(fetchUri, requestOptions);
    return response.ok;
}

export const moveDrugManualReviewQueue = async (drug, queue) => {
    const fetchUri = new URL(`${config.REFINED_API_HOST}/api/drugs/${drug.id}/manual_review/`);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            queue: queue,
        })
    };

    const response = await fetch(fetchUri, requestOptions);
    return response.ok;
}
